import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import './player.css'

class VidPlayer extends Component {
  state = {
    pip: false,
    playing: true,
    controls: true,
    loop: false,
    showEndScreen: false,
    clickArrowEndScreen: false,
    nextLessonEndScreen: false,
    completedEndScreen: false
  }

  // ensures that the video starts as soon as it loads so user doesn't have to click play button; also removes the end screen message
  handlePlay = () => {
    console.log('onPlay')
    this.setState({ playing: true, showEndScreen: false, clickArrowEndScreen: false, nextLessonEndScreen: false, completedEndScreen: false })
  }

  // End of vid handler for automatically starting next video when current video ends
  // handleEnded = () => {
  //   console.log('onEnded')
  //   this.setState({ playing: this.state.loop })
  //   return (
  //     this.props.updateCurrVid()
  //   )  
  // }

  // End of vid handler for displaying "click arrow for next vid" rather than automatically starting next vid
  handleEnded = () => {
    console.log('onEnded')
    return(
      this.props.currVid < (this.props.lessonVids.length - 1) 
        ? this.setState({ showEndScreen: true, clickArrowEndScreen: true }) 
        : this.props.lesson.title !== "Lesson 3"
          ? this.setState({ showEndScreen: true, nextLessonEndScreen: true })
          : this.setState({ showEndScreen: true, completedEndScreen: true })
    )    
  }

  closeEndScreen = () => {
    this.setState({ showEndScreen: false, clickArrowEndScreen: false, nextLessonEndScreen: false, completedEndScreen: false })
  }

  ref = player => {
    this.player = player
  }

  render () {
    const lessonUrl = this.props.lessonUrl;
    const { playing, controls, pip } = this.state

    return (
          <div className='player-wrapper'>
            <ReactPlayer
              ref={this.ref}
              className='react-player'
              width='100%'
              height='100%'
              url={lessonUrl}
              pip={pip}
              playing={playing}
              controls={controls}
              onReady={this.handlePlay}
              onEnded={this.handleEnded}
              onError={e => console.log('onError', e)}
            />
            {this.state.showEndScreen &&
              <div className="end-screen">
                <h3>
                  {this.state.clickArrowEndScreen 
                    ? "Click the arrow to play the next video."
                    : this.state.nextLessonEndScreen
                      ? "Use the links below to start the next lesson."
                      : "Thanks for completing the Water4Otter Online Curriculum!"
                  }
                </h3>
                <button onClick={() => this.closeEndScreen()}>X</button>
              </div>
            }
          </div>
    )
  }
}

export default VidPlayer