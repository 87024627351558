import React from "react";
import styled from "styled-components";

const StyledTitle = styled.div`
  width: 100%;
  margin: 3rem 0;
  text-align: center;
  h1,
  h3 {
    font-size: 2rem;
  }
`;

const LessonTitle = ({ title, subtitle }) => {
  return (
    <StyledTitle>
      {title && <h1>{title + (subtitle && subtitle !== "" ? ":" : "")}</h1>}
      {subtitle && <h3>{subtitle}</h3>}
    </StyledTitle>
  );
};

export default LessonTitle;
