import React from "react";
import { Col } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styled from "styled-components";

const StyledNav = styled.button`
  height: 100%;
  width: 100%;
  border: none;
  background: #f3f3f3;
  color: #c4c4c4;
  padding: 0.5rem;
  transform: scale(1);
  transition: transform 0.3s ease;
  font-size: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    // position: relative;
    // top: 13%;
    // left: -40%;
  }
  p {
    position: relative;
    top: -20px;
    font-size: 0.9rem;
  }
  :hover {
    cursor: default;
  }
  &.active {
    color: #f1751a;
    :hover {
      transform: scale(1.1);
      cursor: pointer;
      transition: transform 0.3s ease;
    }
  }
  @media only screen and (min-width: 1500px) {
    font-size: 20rem;
  }
`;

const LessonNav = ({ dir, lessonVids, currVid, setCurrVid }) => {
  const updateCurrVid = (prevNext) => {
    return prevNext === "prev"
      ? currVid <= 0
        ? null
        : setCurrVid(currVid - 1)
      : currVid >= lessonVids.length - 1
        ? null
        : setCurrVid(currVid + 1);
  };
  return (
    <Col xs={{ span: 6, order: dir === 'next' ? 3 : 2 }} md={{ span: 2, order: dir === 'next' ? 4 : 2 }}>
      <StyledNav
        onClick={dir === 'prev' ? () => updateCurrVid("prev") : () => updateCurrVid("next")}
        className={dir === 'prev' && currVid > 0 ? `active` : dir === 'next' && currVid < lessonVids.length - 1 ? 'active' : undefined}
      >
        {dir === "next" ? <MdKeyboardArrowRight /> : <MdKeyboardArrowLeft />}
        <p>{dir === 'next' ? "Next" : "Back"}</p>
      </StyledNav>
      {/* {currVid === 0 && lesson.lessonId !== 0 && <WMKLink to={`/${allLessonsArr[lesson.lessonId - 1].slug}#vid-player-wrap`}><button className="prevNext lesson">{`Previous Lesson: ` + allLessonsArr[lesson.lessonId - 1].title}</button></WMKLink>  } */}
    </Col>
  );
};

export default LessonNav;
