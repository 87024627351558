import React, { useState
  //, useEffect, useRef
 } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { WMKLink } from "wmk-lib";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
// import Vimeo from "react-player"; //'react-vimeo'
import LessonNav from "./Player/LessonNav";
import LessonTitle from "./LessonTitle";
import VidPlayer from "./Player/VidPlayer";

// const vidTitleHeight = "5rem";
// const vimeoBase = "https://vimeo.com/";

const WrapCont = styled(Container)`
  .video-wrap {
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .vimeo {
      width: 100%;
      height: 100%;
      .player.no-fullscreen-support .fullscreen {
        display: block !important;
      }
      .vimeo-image {
        background-size: cover;
        display: flex !important;
        justify-content: center;
        align-items: center;
        .vimeo-play-button {
          opacity: 0.9;
          width: 12rem;
          border: 20px solid white;
          background: rgba(0, 0, 0, 0.2);
          padding: 20px 10px 20px 30px;
          border-radius: 50%;
          box-shadow: 0px 0px 5px gray;
          svg {
            overflow: hidden;
            vertical-align: middle;
            fill: white;
          }
        }
      }
      .vimeo-embed {
        width: 100%;
        height: 100%;
        iframe {
          width: 100%;
          height: 100%;
          // box-shadow: 40px 40px 40px lightgray;
        }
      }
    }
  }
  .lesson-list-wrap {
    font-size: 1.2rem;
    margin: 4rem 0;
    .centered {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      a {
        text-decoration: underline;
        color: black;
        margin-bottom: 1rem;
        display: block;
        width: 6rem;
        &.active {
          color: #fe822c;
          text-decoration: none;
        }
        :hover {
          color: #fe822c;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .video-wrap {
      height: 22rem;
    }
  }
  @media only screen and (max-width: 991px) {
    .video-wrap {
      height: 16rem;
    }
  }
  @media only screen and (max-width: 767px) {
    .video-wrap {
      height: 18rem;
    }
  }
`;

// const CustomPlayButton = styled.button`

// `

const LessonPlayer = ({ lesson }) => {
  //console.log("lesson: ", lesson)
  const { allLessons } = useStaticQuery(query);
  const allLessonsArr = allLessons.edges.map((p) => p.node);


  const lessonVids = lesson && lesson.vimeo && lesson.vimeo;

  const [currVid, setCurrVid] = useState(0);
  const updateCurrVid = () => {
    return currVid < lesson.vimeo.length - 1 ? setCurrVid(currVid + 1) : null;
  };

  return (
    <WrapCont id="vid-player-wrap">
      <Row>
        <Col>
          <LessonTitle title={lesson.title} subtitle={lesson.subtitle} />
        </Col>
      </Row>
      <Row>
        <LessonNav
          dir="prev"
          currVid={currVid}
          setCurrVid={setCurrVid}
          lessonVids={lessonVids}
        />
        <Col xs={{ span: 12, order: 1 }} md={{ span: 8, order: 2 }}>
          <div className="video-wrap">
            <VidPlayer
              lesson={lesson}
              lessonUrl={lesson.vimeo[currVid].url}
              lessonVids={lesson.vimeo}
              currVid={currVid}
              updateCurrVid={updateCurrVid}
            />
          </div>
        </Col>
        <LessonNav
          dir="next"
          currVid={currVid}
          setCurrVid={setCurrVid}
          lessonVids={lessonVids}
        />
      </Row>
      <Row className="lesson-list-wrap">
        {allLessonsArr &&
          allLessonsArr.map((singleLesson, i) => {
            return (
              <Col
                sm={12}
                md={3}
                className="centered"
                key={singleLesson.slug + i}
              >
                <WMKLink
                  to={`/` + singleLesson.slug + "#vid-player-wrap"}
                  className={lesson.lessonId === i ? "active" : ""}
                >
                  {singleLesson.title}
                </WMKLink>
              </Col>
            );
          })}
      </Row>
    </WrapCont>
  );
};

export default LessonPlayer;

const query = graphql`
  {
    allLessons: allLessonsJson(sort: { order: ASC, fields: lessonId }) {
      edges {
        node {
          ...NodeLessonsFields
        }
      }
    }
  }
`;
