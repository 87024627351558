import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowDown, FaExternalLinkAlt } from "react-icons/fa";
import { WMKLink } from "wmk-lib";
import get from "lodash/get";

const WrapCont = styled(Container)`
  margin-top: 3rem;
  padding-bottom: 3rem;
  p {
    color: gray;
    ul {
      li {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .divider-line {
    display: flex;
    justify-content: center;
    width: 100%;
    div {
      height: 1px;
    }
    &.orange {
      div {
        width: 45vw;
        background: #fe822c;
        margin-bottom: 4rem;
      }
    }
    &.blue {
      display: none;
      margin-bottom: 3rem;
      div {
        width: 45vw;
        background: #8ad2ce;
      }
    }
  }

  .check-wrap {
    margin-bottom: 3rem;
    p {
      margin-bottom: 10px;
      color: gray;
    }
    .links-wrap {
      .link {
        display: block;
        width: 100%;
        color: gray;
        text-decoration: underline;
        margin-bottom: 5px;
        :hover {
          color: #fe822c;
        }
        div {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          svg {
            color: #fe822c;
            margin-right: 10px;
          }
          p {
            margin-bottom: 0;
          }
        }
        &.file {
          svg {
            border-bottom: 2px solid #fe822c;
          }
        }
        &.url {
        }
      }
    }
  }

  .obj-wrap {
    margin-bottom: 3rem;
    p,
    ol {
      color: gray;
    }
  }

  .teks-wrap {
    p {
      margin-bottom: 5px;
      color: gray;
      &.subtitle {
        margin-bottom: 10px;
      }
    }
    li {
      color: gray;
    }
  }

  @media only screen and (max-width: 767px) {
    .divider-line {
      width: 90%;
      &.orange {
        div {
          width: 100vw;
        }
      }
      &.blue {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

const Introduction = () => {
  return (
    <>
      <h4>About the Online Curriculum</h4>
      <p>
        Water4Otter is a water conservation program developed by the North Texas
        Municipal Water District to teach youth about their water source and the
        importance of saving water.
      </p>

      <h6>
        <b>Student Goal</b>
      </h6>
      <p>
        To earn a Water4Otter ambassador certificate, which can be printed by
        teachers or parents after students complete all three lessons.
      </p>

      <h6>
        <b>Grade Level</b>
      </h6>
      <p>1st - 3rd grade, but also appropriate for 4th and 5th grades.</p>

      <h6>
        <b>Length and prep</b>
      </h6>
      <p>
        3 lessons, each 12-16 minutes each (includes activity time, within
        lesson). Each lesson is a combination of short videos, including brief
        analog activities. Students only need paper and a writing utensil to get
        started.
      </p>

      <h6>
        <b>Appropriate for Online and In-class</b>
      </h6>
      <p>
        The online curriculum can be student-led with teacher or parent support,
        but can be used in any online learning format (synchronous or
        asynchronous). It can also be used in-person, led by a teacher.
      </p>

      <h6>
        <b>Check for Understanding Options (after each lesson)</b>
      </h6>
      <div>
        <ul>
          <li>
            <p>Coloring Sheets</p>
            <p>&nbsp; - Student-led (PDF, printer required)</p>
          </li>
          <li>
            <p>Online Quiz</p>
            <p>&nbsp; - Student-led (Google Form link)</p>
          </li>
          <li>
            <p>Teacher Slides</p>
            <p>
              &nbsp; - Teacher-led, can be customized (Google Slides,
              PowerPoint)
            </p>
          </li>
          <li>
            <p>Water4Otter Ambassador Certificate</p>
            <p>
              &nbsp; - After Lesson 3, need to be printed by parent or teacher
              (PDF)
            </p>
          </li>
        </ul>
      </div>

      <br />

      <div className="divider-line blue">
        <div />
      </div>

      <br />

      <h4>Curriculum Goals:</h4>
      <div>
        <ul>
          <li>
            <p>
              Increase the number of household conversations about water and
              water conservation.
            </p>
          </li>
          <li>
            <p>
              Educate students about their water source (Lavon Lake) and how
              water is shared by local wildlife, including otters, bobcats, and
              red foxes.
            </p>
          </li>
          <li>
            <p>Define drought, watershed, and wastewater.</p>
          </li>
          <li>
            <p>
              Teach students how to spot wasteful watering practices at home.
            </p>
          </li>
        </ul>
      </div>

      <br />
    </>
  );
};

const Meta = ({ checkLinks, objectives }) => {
  const Icons = {
    file: () => <FaArrowDown size="15px" />,
    url: () => <FaExternalLinkAlt size="15px" />,
  };

  return (
    <>
      <div className="check-wrap">
        <h4>Check for Understanding</h4>
        <p>
          These are supplemental items that can be downloaded or referenced to
          support learning the topics from the lesson.
        </p>
        <div className="links-wrap">
          {checkLinks &&
            checkLinks.map((link, i) => {
              const type = get(link, `linkType`);
              const Icon = Icons[link.linkType];
              // console.log(link)
              return (
                <WMKLink
                  to={link.link}
                  target="blank"
                  className={`link ${type}`}
                  key={link.linkTitle + i}
                >
                  <div>
                    <Icon />
                    <p>{link.linkTitle}</p>
                  </div>
                </WMKLink>
              );
            })}
        </div>
      </div>

      <div className="divider-line blue">
        <div />
      </div>

      <div className="obj-wrap">
        <h4>Objectives</h4>
        <p>Students will be able to:</p>
        <ol>
          {objectives &&
            objectives.map((obj, j) => {
              return <li key={obj + j}>{obj}</li>;
            })}
        </ol>
      </div>
    </>
  );
};

const TEKS = ({ teks }) => {
  return (
    <div className="teks-wrap">
      <h4>TEKS Alignment</h4>
      <p className="subtitle">
        The lesson above is aligned with the following TEKS, adopted 2017:
      </p>
      <ul>
        {teks &&
          teks.map((tek, k) => {
            return (
              <li key={tek.gradeLevel + k}>
                <p>{tek.gradeLevel}</p>
                <div className="tek-codes-wrap">
                  {tek.codes &&
                    tek.codes.map((code, l) => {
                      return <p key={code + l}>{`- ` + code}</p>;
                    })}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const LessonMeta = ({ meta, lesson }) => {
  // console.log(meta, lesson);
  const { checkLinks, objectives, teks } = meta;

  return (
    <WrapCont>
      <Row>
        <Col className="divider-line orange">
          <div />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          {lesson.lessonId === 0 ? (
            <Introduction />
          ) : (
            <Meta checkLinks={checkLinks} objectives={objectives} />
          )}
        </Col>

        <Col sm={12} md={2} />

        <div className="divider-line blue">
          <div />
        </div>

        <Col sm={12} md={4}>
          <TEKS teks={teks} />
        </Col>
      </Row>
    </WrapCont>
  );
};

export default LessonMeta;
