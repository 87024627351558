import React from "react";
import Layout from "../components/layout/MainLayout";
import { graphql } from "gatsby";
import Lesson from "../components/routes/Lesson/Lesson";
import LessonMeta from "../components/routes/Lesson/LessonMeta";
import { SEO } from "wmk-lib";
import get from "lodash/get";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";

export default ({ data }) => {
  const { lesson } = data;
  const { meta } = lesson;
  //console.log(data);
  return (
    <Layout>
      <SEO
        title={get(lesson, `title`)}
        path={"/"}
        slug={get(lesson, `slug`)}
        description={`Water4Otter is a water conservation program developed by the North Texas Municipal Water District to teach youth about their water source and the importance of saving water.`}
        ogImage={null}
        twitterImage={null}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      <Lesson lesson={lesson} />
      <LessonMeta meta={meta} lesson={lesson} />
    </Layout>
  );
};

export const query = graphql`
  query lessons($slug: String!) {
    lesson: lessonsJson(slug: { eq: $slug }) {
      ...NodeLessonsFields
    }
  }
`;
